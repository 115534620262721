<template>
  <b-modal id="modal-new-return-file" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-50">Subir arquivo de retorno</h3>
      <span class="mb-2"> Anexe o seu arquivo abaixo. </span>
      <b-row>
        <b-col cols="12">
          <cc-dropzone v-model="file" subtitle="" accept-formats=".RET, .xlsx" />
        </b-col>
      </b-row>

      <div class="d-flex flex-row justify-content-center mt-2 w-100">
        <b-button
          variant="outline-secondary"
          class="mb-1 mr-1 text-dark font-weight-bolder"
          @click="$bvModal.hide('modal-new-return-file')"
        >
          Cancelar
        </b-button>
        <b-button
          variant="secondary"
          class="mb-1 bg-light-secondary font-weight-bolder"
          :disabled="!file"
          @click="uploadReturnFile"
        >
          Subir arquivo de retorno
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue'

export default {
  name: 'UploadReturnFileModal',
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    CcDropzone,
  },
  data() {
    return {
      file: '',
    }
  },
  methods: {
    async uploadReturnFile() {
      this.$swal({
        title: 'Confirmar upload?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
        preConfirm: async () => {
          this.$swal.showLoading()

          await this.$store.dispatch('admin/uploadReturnFile', this.file)

          this.$bvModal.hide('modal-new-return-file')
          this.$emit('uploaded')

          this.$swal({
            title: 'Sucesso!',
            text: 'Arquivo de retorno enviado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        },
      })
    },
  },
}
</script>
