<template>
  <div>
    <datatable
      ref="returnFile"
      :serviceUrl="serviceUrl"
      :customFields="fields"
      showPath="returnfiles.details"
      showExportToExcell
      :exportToExcellAction="exportToExcel"
    >
      <template #table-header>
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="mb-0">Arquivos disponíveis</h4>
          <div class="d-flex justify-content-end align-items-center">
            <v-select
              v-model="selectedAssignor"
              :options="assignors"
              placeholder="Cedente"
              class="assignor-selector d-flex flex-column mr-1"
            >
            </v-select>

            <v-select
              v-model="selectedStatus"
              :options="statuses"
              placeholder="Status"
              class="assignor-selector d-flex flex-column mr-1"
            >
            </v-select>

            <flat-pickr
              v-model="data_envio"
              class="assignor-selector form-control bg-white"
              :config="config"
              placeholder="Data de vencimento"
            />
          </div>
        </div>
      </template>
    </datatable>
    <upload-return-file-modal @uploaded="$refs.returnFile.refresh()" />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
  brazilianFormattedDate,
  returnFileStatusFormatter,
} from '@core/comp-functions/data_visualization/datatable'
import { EventBus } from '@/services/event_bus'
import UploadReturnFileModal from '../components/UploadReturnFileModal.vue'
import downloadFile from '@/utils/download-file'

export default {
  name: 'ReturnFiles',
  components: {
    BCard,
    vSelect,
    flatPickr,
    Datatable,
    UploadReturnFileModal,
  },
  data() {
    return {
      serviceUrl: '/admin/trademasters/retorno_bancario',
      selectedStatus: null,
      selectedAssignor: null,
      data_envio: '',
      assignors: [],
      statuses: [
        { value: 'pendente', label: 'Pendete' },
        { value: 'importado', label: 'Importado' },
        { value: 'processado', label: 'Processado' },
        { value: 'erro', label: 'Erro' },
      ],
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      fields: [
        {
          key: 'id',
          label: 'Número',
        },
        {
          key: 'cedente.nome',
          label: 'Cedente',
        },
        {
          key: 'created_at',
          label: 'Data de envio',
          formatter: brazilianFormattedDate,
        },
        {
          key: 'qtd_registros',
          label: 'Qtd. de eventos',
        },
        {
          key: 'status',
          label: 'Status',
          formatter: returnFileStatusFormatter,
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    }
  },
  async mounted() {
    EventBus.$on('new_return_file', () => {
      this.$bvModal.show('modal-new-return-file')
    })
    this.getAssignors()
  },
  destroyed() {
    EventBus.$off('new_return_file')
  },
  methods: {
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((assignor) => ({
        value: assignor.id,
        label: assignor.nome,
      }))
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    async exportToExcel() {
      try {
        const { data } = await this.$store.dispatch(
          'admin/exportReturnFiles',
          this.$refs.returnFile.params,
        )
        downloadFile(data, 'arquivos de retorno.xlsx', 'text/xlsx')
      } catch (error) {}
    },
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedStatus) params.status = this.selectedStatus.value
      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.value
      if (this.data_envio) params.created_at = this.data_envio

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.returnFile.updateFilterValues(newValue)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.assignor-selector {
  min-width: 200px;
}
</style>
